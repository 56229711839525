import Configs from "../helpers/Configs";
import "moment/locale/pt";

export function getMoradas() {
  return new Promise((resolve, reject) => {
    return fetch("/user/getAddresses", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => console.log(error));
  });
}

export function getUserContacts() {
  return new Promise((resolve, reject) => {
    return fetch("/user/getContacts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => console.log(error));
  });
}

export function getUserContactsRede(id_cliente) {
  return new Promise((resolve, reject) => {
    return fetch("/user/getContactsRede", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id_cliente: id_cliente
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        reject();
      });
  });
}

export function getUserInformacoes() {
  return new Promise((resolve, reject) => {
    return fetch("/user/getUserInformation", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ data: json });
      })
      .catch(error => console.log(error));
  });
}

export function changeInfoPessoal(informacao, tipo) {
  return new Promise((resolve, reject) => {
    return fetch("/user/changeInfoPessoal", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        NomeCompleto: informacao.nomecompleto,
        DataNascimento: informacao.datanascimento,
        Email: informacao.email,
        idFicheiroFoto: informacao.idFicheiroFoto,
        id_sexo: informacao.id_sexo,
        Particular: informacao.Particular
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        resolve({
          success: false,
          message: "Erro ao conectar ao servidor. Por favor tente mais tarde."
        });
      });
  });
}

export function changeOutraInfo(informacao, tipo) {
  return new Promise((resolve, reject) => {
    return fetch("/user/setOutrasInfo", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_Sexo: informacao.id_sexo,
        id_pais: informacao.id_nacionalidade,
        DistritoNacionalidade: informacao.distritonacionalidade,
        ConcelhoNacionalidade: informacao.concelhonacionalidade,
        Naturalidade: informacao.naturalidade,
        Empresa: informacao.empresa,
        Cargo: informacao.cargo,
        Nif: informacao.nif,
        ID_TipoDocumento: informacao.id_tipodocumento,
        NumeroDocumento: informacao.numerodocumento,
        DataValidadeDocumento: informacao.datavalidadedocumento,
        cv: informacao.CV,
        idFicheiroCV: informacao.idFicheiroCV,
        NumeroSIGO: informacao.numerosigo
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
}

export function changeParametrizacoes(informacao, Balertas, Bgerirclientes, tipo) {
  // return (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    var alertas = null;
    var gerirClientes = null;
    if (Balertas) {
      alertas = "S";
    } else {
      alertas = "N";
    }
    if (Bgerirclientes) {
      gerirClientes = "S";
    } else {
      gerirClientes = "N";
    }
    return fetch("/user/setParams", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        Alertas: alertas,
        GerirClientes: gerirClientes,
        ID_Lingua: informacao.id_lingua,
        ID_Moeda: informacao.id_moeda
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
  // };
}

// Encomendas - Obter encomendas
export function getOrders() {
  return new Promise((resolve, reject) => {
    return fetch(Configs.myIHTP.tableConfig.encomendas.data.route, {
      method: Configs.myIHTP.tableConfig.encomendas.data.method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ data: json });
      })
      .catch(error => console.log(error));
  });
}

//OBTER ENCOMENDAS BACKOFFICE
export function getOrdersBackOffice(page, pageSize, orderField, orderAsc, orderId) {
  return new Promise((resolve, reject) => {
    return fetch("/encomendas/getAllOrdersBackOffice", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        Pagina: page,
        ResultadosPorPagina: pageSize,
        CampoOrdenacao: orderField,
        CampoOrdenacaoAsc: orderAsc,
        IdEncomenda: orderId
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => console.log(error));
  });
}

export function getAllComissions() {
  return new Promise((resolve, reject) => {
    return fetch("/user/getAllComissions", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ data: json });
      })
      .catch(error => console.log(error));
  });
}

//Funcao para ir buscar o detalhe das comissoes
export function getComissionsDetails(mes, ano) {
  return new Promise((resolve, reject) => {
    return fetch("/user/getComissionsDetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        Mes: mes,
        Ano: ano
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve({ data: json });
      })
      .catch(error => console.log(error));
  });
}

export function getPayments() {
  return new Promise((resolve, reject) => {
    return fetch("/user/getUserPayments", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ data: json });
      })
      .catch(error => console.log(error));
  });
}

export function getOrderDetails(id_encomenda, ID_Cliente) {
  return new Promise((resolve, reject) => {
    return fetch("/encomendas/getdetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_Encomenda: id_encomenda,
        ID_Cliente: ID_Cliente
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
}

export function getOrderDetailsBackOffice(id_encomenda) {
  return new Promise((resolve, reject) => {
    return fetch("/encomendas/getdetailsBackOffice", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_Encomenda: id_encomenda
      })
    })
      .then(res => res.json())
      .then(json => {

        if (typeof json.success === 'undefined') {
          reject();
        } else {
          resolve(json);
        }
      })
      .catch(error => {
        reject();
      });
  });
}

var moment = require("moment");

export function getAllBirthday() {
  return new Promise((resolve, reject) => {
    return fetch("/dashboard/getAllBirthday", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_Cliente: 10049
      })
    })
      .then(res => res.json())
      .then(json => {
        if (json.length > 0) {
          resolve({ Birthdays: json });
        }
      })
      .catch(function (error) {
        reject();
      });
  });
}

export function getAllDaySchedules() {
  return new Promise((resolve, reject) => {
    return fetch("/dashboard/getAllDaySchedules", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        if (!json.error) {
          if (json.length) {
            resolve({ daySchedules: json });
          }
        } else {
          reject();
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getListNewRegisters() {
  return new Promise((resolve, reject) => {
    return fetch("/dashboard/getAllLatestRecords", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        if (!json.error) {
          if (json.length) {
            resolve({ lastRecords: json });
          }
        } else {
          reject();
        }
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getAllLatestOrders() {
  return new Promise((resolve, reject) => {
    return fetch("/dashboard/getAllLatestOrders", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        if (!json.error) {
          if (json.length > 0) {
            resolve({ latestOrders: json });
          }
        } else {
          reject();
        }
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getAllNextCourses() {
  return new Promise((resolve, reject) => {
    return fetch("/dashboard/getAllNextCourses", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getAllLatestInscriptions() {
  return new Promise((resolve, reject) => {
    return fetch("/dashboard/getAllLatestInscriptions", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getComparativeAnnual() {
  return new Promise((resolve, reject) => {
    return fetch("/indicadores/getComparativeAnnual", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ comparativeAnnual: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getComparativeMonthly() {
  return new Promise((resolve, reject) => {
    return fetch("/indicadores/getComparativeMonthly", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ comparativeMonthly: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getCurrentMonthBilling() {
  return new Promise((resolve, reject) => {
    return fetch("/indicadores/getCurrentMonthBilling", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ currentMonthBilling: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getAccumulatedBilling() {
  return new Promise((resolve, reject) => {
    return fetch("/indicadores/getAccumulatedBilling", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ accumulatedBilling: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getBilling1Trimester() {
  return new Promise((resolve, reject) => {
    return fetch("/indicadores/getBilling1Trimester", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ billing1Trimester: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getBilling2Trimester() {
  return new Promise((resolve, reject) => {
    return fetch("/indicadores/getBilling2Trimester", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ billing2Trimester: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getBilling3Trimester() {
  return new Promise((resolve, reject) => {
    return fetch("/indicadores/getBilling3Trimester", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ billing3Trimester: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getBilling4Trimester() {
  return new Promise((resolve, reject) => {
    return fetch("/indicadores/getBilling4Trimester", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ billing4Trimester: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getBusinessLevel() {
  return new Promise((resolve, reject) => {
    return fetch("/indicadores/getBusinessLevel", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ businessLevel: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getCumulativeVolumeMonth() {
  return new Promise((resolve, reject) => {
    return fetch("/indicadores/getCumulativeVolumeMonth", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ cumulativeVolumeMonth: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getCumulativeVolumeYear() {
  return new Promise((resolve, reject) => {
    return fetch("/indicadores/getCumulativeVolumeYear", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ cumulativeVolumeYear: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getPersonalConsumptionMonth() {
  return new Promise((resolve, reject) => {
    return fetch("/indicadores/getPersonalConsumptionMonth", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ personalConsumptionMonth: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}


export function getRecommendation() {
  return new Promise((resolve, reject) => {
    return fetch("/indicadores/getRecommendation", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ recommendation: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getMonthRentability() {
  return new Promise((resolve, reject) => {
    return fetch("/indicadores/getMonthRentability", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ monthRentability: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getTodayRentability() {
  return new Promise((resolve, reject) => {
    return fetch("/indicadores/getTodayRentability", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ todayRentability: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getPremium() {
  return new Promise((resolve, reject) => {
    return fetch("/premios/getPremium", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ premios: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getVales() {
  return new Promise((resolve, reject) => {
    return fetch("/user/getVouchers", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getCourses() {
  return new Promise((resolve, reject) => {
    return fetch("/meusProdutos/getCourses", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ courses: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getProducts() {
  return new Promise((resolve, reject) => {
    return fetch("/meusProdutos/getProducts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ products: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getResaleProducts() {
  return new Promise((resolve, reject) => {
    return fetch("/meusProdutos/getResaleProducts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ resaleProducts: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getSubscriptions() {
  return new Promise((resolve, reject) => {
    return fetch("/meusProdutos/getSubscriptions", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// Previsão Mês Atual
export function getCurrentMonthForecast() {
  return new Promise((resolve, reject) => {
    return fetch("/dashboard/getCurrentMonthForecast", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getComparativeMonthlyDashboard() {
  return new Promise((resolve, reject) => {
    return fetch("/dashboard/getComparativeMonthly", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ ComparativeMonthly: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getComparativeAnnuallyDashboard() {
  return new Promise((resolve, reject) => {
    return fetch("/dashboard/getComparativeAnnually", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ ComparativeAnnually: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getContractValidate() {
  return new Promise((resolve, reject) => {
    return fetch("/dashboard/contractValidate", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(function (json) {
        resolve({ contractValidate: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function editMonthForecast(monthForecast, id) {
  return new Promise((resolve, reject) => {
    return fetch("/dashboard/editMonthForecast", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        PrevisõesPessoais: monthForecast.PrevisõesPessoais,
        PrevisõesKits: monthForecast.PrevisõesKits,
        PrevisõesClientes: monthForecast.PrevisõesClientes,
        ID_Forecast: id
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
}

export function addMonthForecast(monthForecast) {
  return new Promise((resolve, reject) => {
    return fetch("/dashboard/insertForecast", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        VolumeClientes_P: monthForecast.VolumeClientes_P,
        VolumePessoal_P: monthForecast.VolumePessoal_P,
        VolumeKits_P: monthForecast.VolumeKits_P,
        ano: monthForecast.ano,
        mes: monthForecast.mes
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
}

export function editContactInfo(contactInfo, idClienteContacto) {
  return new Promise((resolve, reject) => {
    return fetch("/user/editContact", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        TipoContacto: contactInfo.TipoContacto,
        Descricao: contactInfo.Descricao,
        Principal: contactInfo.Principal,
        ID_ClienteContacto: idClienteContacto
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
}

export function addContact(contactInfoAdd) {
  return new Promise((resolve, reject) => {
    return fetch("/user/addContact", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        Principal: contactInfoAdd.Principal,
        Descricao: contactInfoAdd.Descricao,
        ID_TipoContacto: contactInfoAdd.TipoContacto
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
}

export function editMoradaInfo(moradaInfo, id) {
  return new Promise((resolve, reject) => {
    return fetch("/user/updateAddress", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_Tipo_Morada: moradaInfo.ID_Tipo_Morada,
        ID_Cliente_Morada: id,
        Localidade: moradaInfo.Localidade,
        CodigoPostal: moradaInfo.CodigoPostal,
        ID_Pais: moradaInfo.ID_Pais,
        Morada: moradaInfo.Morada,
        Nome: moradaInfo.Nome,
        Nif: moradaInfo.Nif,
        Principal: moradaInfo.Principal
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
}

export function addMoradas(moradaInfoAdd) {
  return new Promise((resolve, reject) => {
    return fetch("/user/addAddress", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        Localidade: moradaInfoAdd.Localidade,
        CodigoPostal: moradaInfoAdd.CodigoPostal,
        ID_Pais: moradaInfoAdd.ID_Pais,
        Nome: moradaInfoAdd.Nome,
        Nif: moradaInfoAdd.Nif,
        Principal: moradaInfoAdd.Principal,
        ID_Tipo_Morada: moradaInfoAdd.ID_Tipo_Morada,
        Morada: moradaInfoAdd.Morada
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
}

export function validateCode(CodigoVale) {
  return new Promise((resolve, reject) => {
    return fetch("/vouchers/addVale", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        Codigo: CodigoVale
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
}

export function validateCodeGuest(CodigoVale) {
  return new Promise((resolve, reject) => {
    return fetch("/vouchers/addValeGuest", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        Codigo: CodigoVale
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
}

export function sendVoucher(id, email) {
  return new Promise((resolve, reject) => {
    return fetch("/vouchers/sendVoucher", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        Codigo: id,
        email: email
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
}

export function getDownloads() {
  return new Promise((resolve, reject) => {
    return fetch("/downloads/getDownloads", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ downloads: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getBaseInformation() {
  return new Promise((resolve, reject) => {
    return fetch("/dashboard/getUpline", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ information: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getNotificacoesEmail() {
  return new Promise((resolve, reject) => {
    return fetch("/notifications/getSubscriptions", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ notificacoes: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function editPassword(passwordInfo) {
  return new Promise((resolve, reject) => {
    return fetch("/user/changePasswordMyIHTP", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        Password: passwordInfo.newPassword,
        ConfirmPassword: passwordInfo.confirmNewPassword
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        resolve({
          success: false,
          message: "Erro ao conectar ao servidor"
        });
      });
  });
}

export function calcelSubscription(id) {
  return new Promise((resolve, reject) => {
    return fetch("/meusprodutos/cancelSubscriptions", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_Subscricao: id
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
}

export function getTipoMoradas() {
  return new Promise((resolve, reject) => {
    return fetch("/geral/getTypeAddresses", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ moradas: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getTipoContactos() {
  return new Promise((resolve, reject) => {
    return fetch("/geral/getTypeContacts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ contactos: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getResumeComissoes() {
  return new Promise((resolve, reject) => {
    return fetch("/user/getResume", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ comissoes: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getMediaCenterAll(mediaType) {
  return new Promise((resolve, reject) => {
    return fetch("/mediacenter/getMediaCenterAll", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        MediaType: mediaType
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve({ mediacenter: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// Obter tipos de media
export function getTipoMedia() {
  return new Promise((resolve, reject) => {
    return fetch("/mediacenter/getTipoMedia", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ tipoMedia: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getDocumentsTypes() {
  return new Promise((resolve, reject) => {
    return fetch("/user/getDocumentsTypes", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ data: json });
      })
      .catch(error => console.log(error));
  });
}

// Obter desafios “http://localhost:4040/notifications/getDesafios”
export function getDesafios() {
  return new Promise((resolve, reject) => {
    return fetch("/notifications/getDesafios", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({ desafios: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getDataFiltered(filter) {
  return new Promise((resolve, reject) => {
    return fetch("/mediacenter/getDataFiltered", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        filter: filter
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve({ mediacenter: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// Obter tipos de media
export function removeAddress(id) {
  return new Promise((resolve, reject) => {
    return fetch("/user/removeAddress", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_Cliente_Morada: id
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function deleteContact(id) {
  return new Promise((resolve, reject) => {
    return fetch("/user/removeContact", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_ClienteContacto: id
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function cancelNotification(id) {
  return new Promise((resolve, reject) => {
    return fetch("/notifications/cancelNotification", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        id_alertasubscrito: id
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function subscribeNotifications(type, desafio) {
  return new Promise((resolve, reject) => {
    return fetch("/notifications/subscribeNotification", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_TipoAlertaSubscrito: type,
        ID_Desafio: desafio
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function deletePrevisao(id) {
  return new Promise((resolve, reject) => {
    return fetch("/dashboard/removeForecast", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_Forecast: id
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function uploadFoto(event) {
  return new Promise((resolve, reject) => {
    var formData = new FormData();
    formData.append("sampleFile", event[0]);

    return fetch("/uploadFileThumbnail", {
      method: "POST",
      headers: {},
      credentials: "include",
      body: formData
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject({
          success: false,
          message: "Ocorreu um erro inesperado ao carregar nova foto de Perfil!"
        });
      });
  });
}

export function uploadFicheiro(event) {

  var countOfArray = event.length;
  if (event[countOfArray - 1].size <= 0) throw 'Ficheiro inválido';

  return new Promise((resolve, reject) => {
    var formData = new FormData();
    formData.append("sampleFile", event[countOfArray - 1]);

    return fetch("/uploadFileBackOffice", {
      method: "POST",
      credentials: "include",
      body: formData
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        console.log(error.message);
        resolve({
          success: false,
          message: "Falha na comunicação com API"
        });
      });
  });
}

export function uploadVitaminaCDN(event) {

  var countOfArray = event.length;
  if (event[countOfArray - 1].size <= 0) throw 'Ficheiro inválido';

  return new Promise((resolve, reject) => {
    var formData = new FormData();
    formData.append("sampleFile", event[countOfArray - 1]);

    return fetch("/uploadVitaminas", {
      method: "POST",
      headers: {},
      credentials: "include",
      body: formData
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        console.log(error.message);
        resolve({
          success: false,
          message: "Falha na comunicação com API"
        });
      });
  });
}

export function deleteOrder(id) {
  return new Promise((resolve, reject) => {
    return fetch("/encomendas/cancelOrder", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_Encomenda: id
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function deleteOrderBackOffice(id) {
  return new Promise((resolve, reject) => {
    return fetch("/admin/cancelOrderBackOffice", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_Encomenda: id
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function verificarEmail(email) {
  return new Promise((resolve, reject) => {
    return fetch("/admin/verifyEmail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        email_verificacao: email
      })
    })
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

//Funcao para fazer impersonate
export function impersonate(id_cliente) {
  return new Promise((resolve, reject) => {
    return fetch("/user/impersonate", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        id_cliente: id_cliente
      })
    })
      .then(res => resolve(res.json()))
      .catch(function (error) {
        reject(error);
      });
  });
}

//Funcao para mudar tipo de conta
export function changeAccountType(tipo_conta) {
  return new Promise((resolve, reject) => {
    return fetch("/admin/changeAccountType", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        tipo_conta: tipo_conta
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve({ message: json });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

//Funcao para mudar percentagem de desconto de um consultor
export function RefreshTipoConsultor(consultor) {
  return new Promise((resolve, reject) => {
    return fetch("/admin/RefreshTipoConsultor", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_Consultor: consultor.id_consultor,
        Valor: consultor.tipo_desconto
      })
    })
      .then(res => resolve(res.json()))
      .catch(function (error) {
        reject(error);
      });
  });
}

//01-07-2019 - Afonso - funcao para criar faturas para clientes empresariais
export function insertInvoiceBusiness(ID_Encomenda) {
  return new Promise((resolve, reject) => {
    return fetch("/admin/insertInvoiceBusiness", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_Encomenda: ID_Encomenda
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

//19-08-2019 - Afonso - funcao para adiconar produto a carrinho de cliente
export function adicionarProduto(carrinho) {
  return new Promise((resolve, reject) => {
    return fetch("/admin/addProductsToUserCart", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_cliente: carrinho.id_cliente,
        ID_Produto: carrinho.id_produto,
        Quantidade: carrinho.quantidade
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

//01-07-2019 - Afonso - Endpoint para gerar faturas
export function insertInvoice(ID_Encomenda) {
  return new Promise((resolve, reject) => {
    return fetch("/admin/insertInvoice", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_Encomenda: ID_Encomenda
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

//01-07-2019 - Afonso - Endpoint para gerar recibos
export function insertReceipt(ID_Encomenda) {
  return new Promise((resolve, reject) => {
    return fetch("/admin/insertReceipt", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_Encomenda: ID_Encomenda
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        resolve({
          success: false,
          message: "Ocorreu um erro inesperado a comunicar com o servidor para inserir o Recibo!"
        });
      });
  });
}

//05-07-2019 - Afonso - Endpoint para gerar nota de credito
export function insertCreditNote(ID_Encomenda) {
  return new Promise((resolve, reject) => {
    return fetch("/admin/insertCreditNote", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_Encomenda: ID_Encomenda
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 08-07-2019 - Ir buscar os clientes da rede para as previsoes da rede - [AFONSO]
export function getForecastRede() {
  return new Promise((resolve, reject) => {
    return fetch("/dashboard/forecastRede", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        if (!json.error) {
          if (json.length) {
            resolve({ forecastRede: json });
          }
        } else {
          reject();
        }
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 08-07-2019 - Ir buscar as previsoes de um elemento da rede - [AFONSO]
export function getCurrentMonthForecastRede(id_cliente) {
  return new Promise((resolve, reject) => {
    return fetch("/dashboard/getCurrentMonthForecastRede", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_Cliente: id_cliente
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 08-07-2019 - Ir buscar os clientes da rede para as previsoes da rede - [AFONSO]
export function registosNaoConcluidos() {
  return new Promise((resolve, reject) => {
    return fetch("/dashboard/registosNaoConcluidos", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        if (!json.error) {
          if (json.length) {
            resolve({ Registos: json });
          }
        } else {
          reject();
        }
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 10-07-2019 - Ir buscar os clientes com registo por concluir com encomendas - [AFONSO]
export function registosNaoConcluidosEncomendas() {
  return new Promise((resolve, reject) => {
    return fetch("/dashboard/registosNCEncomendas", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 08-07-2019 - Ir buscar downlines com contrato a expirar nos proximos 3 meses - [AFONSO]
export function expirateDateDownline() {
  return new Promise((resolve, reject) => {
    return fetch("/dashboard/expirateDateDownline", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        if (!json.error) {
          if (json.length) {
            resolve({ Registos: json });
          }
        } else {
          reject();
        }
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 05-08-2019 - adicionar visualizacao de mediacenter - [AFONSO]
export function updateMediacenterPlayed(id) {
  return new Promise((resolve, reject) => {
    return fetch("/mediacenter/mediacenterPlayed", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_Conteudo: id
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getSubscriptionsUser() {
  return new Promise((resolve, reject) => {
    return fetch("/subscriptions/getSubscriptions", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => console.log(error));
  });
}

export function cancelSubscriptionsUser(id) {
  return new Promise((resolve, reject) => {
    return fetch("/subscriptions/cancelSubscription", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ID_Subscription: id
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => console.log(error));
  });
}

//19-08-2019 - Afonso - funcao para criar email solfut
export function createVoucherClient(valeInfo) {
  return new Promise((resolve, reject) => {
    return fetch("/admin/createValeCliente", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_Cliente: valeInfo.id_cliente,
        valorVale: valeInfo.valor
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

//04-09-2019 - Afonso - funcao para obter stars do mes anterior
export function obterStarsClient() {
  return new Promise((resolve, reject) => {
    return fetch("/admin/getClientStars", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function changeLeadConsultant(id_lead, id_consultor) {
  return new Promise((resolve, reject) => {
    return fetch("/admin/changeLeadConsultant", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        Lead: id_lead,
        Consultor: id_consultor
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function addConsultantUnavailablePeriod(dataInicio, dataFim, idConsultor) {
  return new Promise((resolve, reject) => {
    return fetch("/admin/addConsultantUnavailablePeriod", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        DataInicio: dataInicio,
        DataFim: dataFim,
        Consultor: idConsultor
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function removeConsultantUnavailablePeriod(idPeriodo) {
  return new Promise((resolve, reject) => {
    return fetch("/consultant/removeUnavailablePeriod", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        Periodo: idPeriodo
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getConsultantUnavailablePeriods() {
  return new Promise((resolve, reject) => {
    return fetch("/consultant/getUnavailablePeriods", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getCompany(nif, idPais, validateVies) {
  return new Promise((resolve, reject) => {
    return fetch("/admin/getCompany", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        NIF: nif,
        Pais: idPais,
        VIES: validateVies
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function changeCompanyConsultant(nif, id_consultor) {
  return new Promise((resolve, reject) => {
    return fetch("/admin/changeCompanyConsultant", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        NIF: nif,
        Consultor: id_consultor
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getLeadTypes() {
  return new Promise((resolve, reject) => {
    return fetch("/lead/getTypesLead", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function dispatchAllLeadTypes(leadTypes) {
  return (dispatch, getState) => {
    dispatch(allLeadTypes(leadTypes));
  };
}

export function getLeadOriginTypes() {
  return new Promise((resolve, reject) => {
    return fetch("/lead/getLeadOriginTypes", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function addSubscriptionToReceiveLeads(dataInicio, dataFim, idConsultor, idCampanha) {
  return new Promise((resolve, reject) => {
    return fetch("/admin/addSubscriptionToReceiveLeads", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        DataInicio: dataInicio,
        DataFim: dataFim,
        Consultor: idConsultor,
        Campanha: idCampanha
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function createCampaign(idCampanha, descricaoCampanha, idTipoLead, idPais, plataformas, mensagelEmailLead) {

  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      return fetch("/admin/createCampaign", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        credentials: "include",
        body: JSON.stringify({
          Campanha: idCampanha,
          Descricao: descricaoCampanha,
          TipoLead: idTipoLead,
          Pais: idPais,
          Plataformas: plataformas,
          MensagemEmailLead: mensagelEmailLead
        })
      })
        .then(res => res.json())
        .then(json => {
          dispatch(newCampaign(idCampanha));
          resolve(json);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };
}

export function getCampaigns(idCampanha, descricaoCampanha) {
  return new Promise((resolve, reject) => {
    return fetch("/admin/getCampaigns", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        Campanha: idCampanha,
        Descricao: descricaoCampanha
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function deleteCampaign(idCampanha) {
  return new Promise((resolve, reject) => {
    return fetch("/admin/deleteCampaign", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        Campanha: idCampanha,
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function editCampaign(idCampanha, descricaoCampanha, idTipoLead, idPais, plataformas, mensagemEmailLead) {
  return new Promise((resolve, reject) => {
    return fetch("/admin/editCampaign", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        Campanha: idCampanha,
        TipoLead: idTipoLead,
        Pais: idPais,
        Descricao: descricaoCampanha,
        Plataformas: plataformas,
        MensagemEmailLead: mensagemEmailLead
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getCompaniesBy(idConsultor, nif) {

  return new Promise((resolve, reject) => {
    return fetch("/admin/getCompaniesBy", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        Consultor: idConsultor,
        NIF: nif
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getCompaniesByConsultantId(nif) {

  return new Promise((resolve, reject) => {
    return fetch("/consultant/getMyCompanies", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        Consultor: null,
        NIF: nif
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function deleteCompany(idEmpresa) {
  return new Promise((resolve, reject) => {
    return fetch("/admin/deleteCompany", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        Empresa: idEmpresa,
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function checkNIF(nif, pais) {
  return new Promise((resolve, reject) => {
    return fetch("/admin/checkNIFAssociated", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        nif: nif,
        pais: pais
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export function createCompany(nif, nome, localidade, email, telemovel, pais, idCliente) {
  return new Promise((resolve, reject) => {
    return fetch("/admin/createCompany", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        NIF: nif,
        Nome: nome,
        Localidade: localidade,
        Email: email,
        Telemovel: telemovel,
        Pais: pais,
        IdCliente: idCliente
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getLeadsSubscriptions(idCampanha, idConsultor, dataInicio, dataFim, page, pageSize) {
  return new Promise((resolve, reject) => {
    return fetch("/lead/getLeadsSubscriptions", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        Campanha: idCampanha,
        Consultor: idConsultor,
        DataInicio: dataInicio,
        DataFim: dataFim,
        Page: page,
        PageSize: pageSize,
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function deleteLeadsSubscriptions(idSubscricao) {
  return new Promise((resolve, reject) => {
    return fetch("/lead/deleteLeadsSubscriptions", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        Subscricao: idSubscricao,
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getLeads(idLead, idConsultor, idEmpresa, idTipoLead, idOrigemLead, page, pageSize, orderField, orderAsc) {
  return new Promise((resolve, reject) => {
    return fetch("/lead/searchLeads", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        Lead: idLead,
        Consultor: idConsultor,
        Empresa: idEmpresa,
        TipoLead: idTipoLead,
        OrigemLead: idOrigemLead,
        Page: page,
        PageSize: pageSize,
        CampoOrdenacao: orderField,
        CampoOrdenacaoAsc: orderAsc,
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getStockNotifications(pagina, resultadosPorPagina) {
  return new Promise((resolve, reject) => {
    return fetch("/products/getStockNotifications", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        Pagina: pagina,
        ResultadosPorPagina: resultadosPorPagina,
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getPersonalStockNotifications(pagina, resultadosPorPagina) {
  return new Promise((resolve, reject) => {
    return fetch("/products/getPersonalStockNotifications", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        Pagina: pagina,
        ResultadosPorPagina: resultadosPorPagina,
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}


export function setProductDefaultCurrency(productId, currency) {
  return new Promise((resolve, reject) => {
    return fetch("/admin/setProductDefaultCurrency", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_Produto: productId,
        ID_Moeda: currency
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        var response = {
          success: false,
          message: "Ocorreu um erro inesperado ao definir a moeda do produto."
        };
        resolve(response);
      });
  });
}

export function setPackDefaultCurrency(packId, currency) {
  return new Promise((resolve, reject) => {
    return fetch("/admin/setPackDefaultCurrency", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_Pack: packId,
        ID_Moeda: currency
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        var response = {
          success: false,
          message: "Ocorreu um erro inesperado ao definir a moeda do pack."
        };
        resolve(response);
      });
  });
}

export function importFile(file, endpoint) {
  return new Promise((resolve) => {
    var formData = new FormData();
    formData.append("ficheiro", file);
    formData.append("endpoint", endpoint);

    return fetch("/importFile", {
      method: "POST",
      headers: {},
      credentials: "include",
      body: formData
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        var response = {
          success: false,
          message: "Ocorreu um erro inesperado ao importar os clientes."
        };
        resolve(response);
      });
  });
}



export function dispatchNewCompany(nif) {
  return (dispatch, getState) => {
    dispatch(newCompany(nif));
  };
}

export function dispatchNewSubscription(subscription) {
  return (dispatch, getState) => {
    dispatch(newSubscription(subscription));
  };
}

export function dispatchAllCampaigns(campaigns) {
  return (dispatch, getState) => {
    dispatch(allCampaignsLoaded(campaigns));
  };
}

export const CAMPAIGN = "CAMPAIGN";
export const newCampaign = campaign => ({
  type: CAMPAIGN,
  payload: { campaign }
});

export const ALLCAMPAIGNS = "ALLCAMPAIGNS";
export const allCampaignsLoaded = allCampaigns => ({
  type: ALLCAMPAIGNS,
  payload: { allCampaigns }
});

export const COMPANY = "COMPANY";
export const newCompany = company => ({
  type: COMPANY,
  payload: { company }
});

export const SUBSCRIPTION = "SUBSCRIPTION";
export const newSubscription = newSubscription => ({
  type: SUBSCRIPTION,
  payload: { newSubscription }
});

export const LEAD_TYPES = "LEAD_TYPES";
export const allLeadTypes = allLeadTypes => ({
  type: LEAD_TYPES,
  payload: { allLeadTypes }
});

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}